import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import Seo from "../components/seo";
//Images
import { StaticImage } from "gatsby-plugin-image";

const Education = () => {
  return (
    <Layout>
      <Seo
        title="Educación - BIKESAFE"
        description="Con el aumento del uso de la bicicleta, ofrecer un aparcamiento seguro y eficiente en colegios, universidades y escuelas es fundamental. Bike Safe trabaja con instituciones educativas para proporcionar soluciones personalizadas de aparcabicicletas."
      />
      <Container>
        <Row>
          <Col sm="12">
            <h1 className="mt-5 mb-5 text-center">Educación</h1>
          </Col>
        </Row>
        <Row>
          <Col sm="12" className="text-center">
            <h3>Promoviendo el Uso de la Bicicleta en Centros Educativos</h3>
            <p className="flow-text">
              En los últimos años, cada vez más estudiantes optan por la
              bicicleta como medio de transporte para ir a la escuela, la
              universidad o el colegio. Sin embargo, la falta de aparcamientos
              seguros y eficientes en muchos centros educativos puede ser una
              barrera para fomentar este hábito saludable y sostenible. En Bike
              Safe, ofrecemos soluciones de aparcabicicletas que permiten a las
              instituciones educativas brindar un espacio seguro y cómodo para
              sus estudiantes.
            </p>
            <h3>
              Bike Safe: Soluciones Personalizadas para Centros Educativos
            </h3>
            <p className="flow-text">
              La planificación de un aparcamiento para bicicletas en una
              institución educativa puede requerir atención a varios detalles.
              En Bike Safe, trabajamos con colegios, facultades y universidades
              para ofrecer soluciones que se adapten a sus necesidades
              específicas, optimizando el espacio y el presupuesto disponible.
              Nuestro conocimiento en la creación de aparcabicicletas de calidad
              nos permite asesorar a nuestros clientes para lograr el mejor
              resultado.
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm="12" className="text-center">
            <StaticImage
              placeholder="blurred"
              src="../images/new-york.jpg"
              className="shadow mt-5 mb-2 fluid rounded"
            />
            <p>
              <em className="flow-text">
                "Quiero expresar mi agradecimiento a todo el personal de Bike
                Safe por el servicio competente y profesional que prestan.
                Fueron capaces de mostrar la flexibilidad y consideración
                necesarias para atender los requisitos de diseño de mi colegio".
                <strong>
                  {" "}
                  Paul Jordan, director, escuela infantil Thames View, Dagenham
                </strong>
                .
              </em>
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm="12" className="text-center mt-5">
            <h3>Identificar las Necesidades y los Espacios Adecuados</h3>
            <p className="flow-text">
              Conocer el uso actual de las bicicletas en su centro educativo es
              un primer paso importante para planificar un aparcamiento
              eficiente. Realice un recuento de bicicletas en varios días para
              comprender mejor las necesidades. Considere también la ubicación
              del aparcamiento para que sea de fácil acceso y esté integrado de
              manera segura en el entorno escolar.
            </p>
            <ul>
              <li className="border p-2 shade-on-hover">
                ¿Están las bicicletas aparcadas en un espacio fácilmente
                accesible para los estudiantes?
              </li>
              <li className="border p-2 shade-on-hover">
                ¿Las bicicletas están en áreas designadas y seguras, evitando
                ataduras a árboles, postes o bancos?
              </li>
            </ul>
          </Col>
          <Col sm="12" className="text-center">
            <h3>Elija Ubicaciones Estratégicas</h3>
            <p className="flow-text">
              Colocar los aparcabicicletas en áreas visibles y de fácil acceso,
              como las entradas principales, fomenta su uso y previene robos.
              Evite relegar los racks a áreas escondidas, ya que esto puede
              desalentar a los estudiantes y facilitar el robo de bicicletas.
              Una ubicación estratégica mejora el perfil de su programa de
              fomento del uso de la bicicleta.
            </p>
            <h3>Considere Soluciones de Protección Adicional</h3>
            <p className="flow-text">
              La seguridad es clave para fomentar el uso de la bicicleta en
              centros educativos. Considere la instalación de marquesinas y
              aparcamientos cerrados para proteger las bicicletas de los
              elementos y añadir un nivel adicional de seguridad. Las cubiertas
              protegen las bicicletas y a los ciclistas de la lluvia y la nieve,
              mientras que los aparcamientos cerrados reducen el riesgo de robo.
            </p>
          </Col>
        </Row>
        <Row>
          <Col sm="12" className="text-center">
            <StaticImage
              placeholder="blurred"
              src="../images/verticals.jpg"
              className="shadow mt-5 mb-2 fluid rounded"
            />
          </Col>
        </Row>
        <Row>
          <Col sm="12" className="text-center mt-5">
            <h3>Preocupaciones de Seguridad</h3>
            <p className="flow-text">
              Al seleccionar un aparcabicicletas para su centro educativo, es
              importante considerar diversos factores que afectan la seguridad y
              la usabilidad. A continuación, algunas preguntas clave a tener en
              cuenta:
            </p>
            <ul>
              <li className="border p-2 shade-on-hover">
                ¿Qué nivel de seguridad necesita? ¿Los robos de bicicletas son
                un problema en su institución?
              </li>
              <li className="border p-2 shade-on-hover">
                ¿Cuántas bicicletas necesita aparcar y cuánto espacio tiene
                disponible?
              </li>
              <li className="border p-2 shade-on-hover">
                ¿Dónde se instalará el aparcabicicletas? ¿Será en asfalto,
                césped, adoquines o concreto?
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Education;
